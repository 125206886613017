@import "../../../assets/styles/colours";
@import "../../../assets/styles/responsive";

.headerContainer {
  line-height: initial;
  .headerTopRow {
    padding: 10px 15px 5px;
    background-color: $white;
    height: 50px;
    display: flex;
    align-items: center;
    .headerTopCol {
      width: 50%;
      &:last-child {
        text-align: right;
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        justify-content: flex-end;
        li {
          padding: 0px 0 0 15px;
          text-align: left;
          &:first-child {
            @include breakpoint($mobileOnly) {
              display: none;
            }
          }
          h5 {
            color: $primary-black;
            font-size: 14px;
            line-height: 18px;
            margin: 0;
            padding: 2px 0 0 0;
          }
          small {
            display: block;
            color: #606060;
          }
          span.ant-dropdown-trigger.ant-dropdown-link {
            .ant-image {
              width: auto !important;
              height: 40px;
              position: relative;
              overflow: hidden;
              cursor: pointer;
              .ant-image-img{
                display: inline;
                margin: 0 auto;
                height: 100%;
                width: auto;
                z-index: 0;
              }
            }
          }
        }
        &.adminLogout {
          display: contents;
          li span {
            font-size: 24px;
            line-height: 1;
            color: #560cab;
          }
        }
      }
    }
  }
}
// .introjs-arrow {
//   // margin-left: -5px !important;
//   // top: -80px !important;
//   // left: 60% !important;
//   // /* position: relative !important; */
//   // right: unset!important;
//   // transform: rotateY(0deg) rotate(16deg) !important;
//   // bottom: unset!important;
//   // border:none!important;
//   // border-bottom-color: #fff !important;
//   // border-width: 0px 10px 12px 10px !important;
// }
// .introjs-arrow::before ,.introjs-arrow.top-right::before {
//   // content: "";
//   // position: absolute;
//   // border: 2px solid #fff;
//   // border-color: transparent transparent transparent #fff!important;
//   // height: 128px;
//   // top: -26px;
//   // left: -36px;
//   // width: 200px;
//   // border-radius: 490px 0 0 12px;
// }

.introjs-tooltip {
  box-sizing: content-box;
  position: absolute;
  visibility: visible;
  background-color: #00000000!important;
  opacity: 1;
  color: #fff!important;
  min-width: 250px;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: none!important;
  transition: opacity .1s ease-out;
}
.introjs-tooltipbuttons {
  border-top: none!important; 
  padding: 10px;
  text-align: right;
  white-space: nowrap;
}

.introjs-tooltip-header {
  position: relative;
  padding-left: 20px;
  padding: 0!important;
  /* padding-right: 20px; */
  /* padding-top: 10px; */
  min-height: auto;
}
// .customlayer.introjs-bottom-right-aligned  , .customlayer.introjs-bottom-left-aligned  {
//   top: 178px!important;
// }
// .customlayer.introjs-top-left-aligned  , .customlayer.introjs-top-middle-aligned  , .customlayer.introjs-top-right-aligned{
//   bottom: 164.75px !important;
// }

.introjs-overlay {
  position: absolute;
  box-sizing: content-box;
  z-index: 999999;
  opacity: 0.5!important;
  transition: all .3s ease-out;
}
.introjs-prevbutton {
  float: left;
  display: none!important;
}
// .customlayer.introjs-bottom-left-aligned .introjs-arrow{
//   // margin-left: -5px !important;
//   //   top: -80px !important;
//   //   left: 23% !important;
//   //   /* position: relative !important; */
//   //   right: unset !important;
//   //   transform: rotateY(180deg) rotate(3deg) !important;
//   //   bottom: unset !important;
//   //   border:none!important;
//   //   border-bottom-color: #fff !important;
//   //   border-width: 0px 10px 12px 10px !important;
// }
// body .ant-layout .ant-layout-footer {
//   height: 70px;
//   position: fixed;
//   width: 100%;
// }


// .customlayer .introjs-arrow.bottom {
//   // margin-left: -5px !important;
//   // top: 226px !important;
//   // left: 7% !important;
//   // /* position: relative !important; */
//   // right: unset !important;
//   // transform: rotateY(0) rotate(189deg) !important;
//   // bottom: unset !important;
//   // border: none !important;
//   // border-bottom-color: #fff !important;
//   // border-width: 0px 10px 12px 10px !important;
// }
.introjs-arrow {
  display: none !important;
}
// .customlayer .introjs-arrow.bottom::before, .customlayer .introjs-arrow.bottom::before {
//   // content: "";
//   // position: absolute;
//   // border: 2px solid #fff;
//   // border-color: transparent transparent transparent #fff !important;
//   // height: 80px;
//   // top: 4px;
//   // left: -13px;
//   // width: 0;
//   // border-radius: 457px 0 0 12px;
// }
// .customlayer .introjs-arrow.bottom-middle{
//   // margin-left: -5px !important;
//   //   top: 224px !important;
//   //   left: 49% !important;
//   //   /* position: relative !important; */
//   //   right: unset !important;
//   //   transform: rotateY(180deg) rotate(160deg) !important;
//   //   bottom: unset !important;
//   //   border:none!important;
//   //   border-bottom-color: #fff !important;
//   //   border-width: 0px 10px 12px 10px !important;
// }
// .customlayer .introjs-arrow.bottom-middle::before{
  // content: "";
  // position: absolute;
  // border: 2px solid #fff;
  // border-color: transparent transparent transparent #fff !important;
  // height: 100px;
  // top: -26px;
  // left: -22px;
  // width: 200px;
  // border-radius: 145px 0 0 0px;
// }

// .customlayer .introjs-arrow.bottom-right {
//   // margin-left: -5px !important;
//   // top: 226px !important;
//   // left: 75% !important;
//   // /* position: relative !important; */
//   // right: unset !important;
//   // transform: rotateY(0deg) rotate(119deg) !important;
//   // bottom: unset !important;
//   // border: none !important;
//   // border-bottom-color: #fff !important;
//   // border-width: 0px 10px 12px 10px !important;
// }


// .customlayer .introjs-arrow::after {
//   // border-bottom-style: solid;
//   // border-bottom-width: 2px;
//   // border-right-style: solid;
//   // border-right-width: 2px;
//   // content: "";
//   // display: inline-block;
//   // height: 18px;
//   // left: -8px;
//   // position: absolute;
//   // top: 4px;
//   // width: 18px;
//   // transform: rotate(-95deg);
// }
// .customlayer .introjs-arrow.bottom::after {
//   // border-bottom-style: solid;
//   // border-bottom-width: 2px;
//   // border-right-style: solid;
//   // border-right-width: 2px;
//   // content: "";
//   // display: inline-block;
//   // height: 18px;
//   // left: -21px;
//   // position: absolute;
//   // top: 1px;
//   // width: 18px;
//   // transform: rotate(-129deg);
// }

.introjs-overlay{
  inset: unset!important;
}
.customlayer .introjs-tooltiptext {
  padding: 20px 5px;
  font-size: 22px;
  text-align: center;
  font-weight: 400;
  border-radius: 20%;
  font-family: cursive;
  background: rgba(0, 0, 0, 0.5490196078);
  box-shadow: 1px 2px 11px 6px #fff;
}

.customlayer .introjs-nextbutton ,.customlayer .introjs-nextbutton:focus {
  float: right;
  background: #ed841a;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 5px 12px;
}

.customlayer .introjs-nextbutton:hover {
  float: right;
  background: #ed841a;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 5px 12px;
  
}
@media (min-width : 768px){

}
.customlayer .introjs-skipbutton, .customlayer .introjs-skipbutton {
  position: fixed;
  top: 0;
  left: 0;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 45px;
  color: #f5f5f5;
  font-size: 35px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
}
@media (min-width: 768px){
  .customlayer .introjs-tooltiptext{
    font-size: 20px!important;
  }
  #homes {
    left: 4%;
    width: 40px;
    content: "";
    height: 40px;
    top: 9%;
    position: absolute;
    width: 100px;
}
#jobseeker {
  left: 12%;
  width: 40px;
  content: "";
  height: 40px;
  top: 9%;
  position: absolute;
  width: 110px;
}
#onhold {
  position: absolute;
  top: 5px;
  left: 12%;
  height: 40px;
  width: 140px;
}
#archive {
  position: absolute;
  top: 5px;
  left: 25%;
  height: 40px;
  width: 140px;
}
#draft{
  position: absolute;
  top: 5px;
  left: 37%;
  height: 40px;
  width: 140px;
}
}
.show{
  display: block!important;
}


