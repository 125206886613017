$black: #000000;
$white: #ffffff;

$primary-orange: #ED9F0B;
$primary-blue: #2C4394;
$primary-violet: rgba(129, 46, 130, 0.5);
$primary-red: #ED2323;
$primary-black: #2d2d2d;
$secondary-black: #393939;
$primary-green: #187300;
$primary-grey: #d7d7d7;
$secondary-grey: #f3f2f1;
$third-grey: #c1c1c1;
