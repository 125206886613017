@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.jobPostView {
  padding: 15px;
  margin: 0;
  background-color: #fff;
  /* border: 10px solid rgba(237, 132, 26, 0.5); */
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  h3 {
    background-color: #A0ADC3;
    padding: 10px;
    border-radius: 5px;
    margin: 20px 0;
    color: $white;
  }
  .jobsDetailsRow {
    border: 0px solid rgba(237, 132, 26, 0.5);
    border-radius: 10px;
    // box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
    background-color: $white;
    padding: 15px;
    margin: 0 0 15px 0;
    @include breakpoint($mobileOnly) {
      padding: 0px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      @include breakpoint($mobileOnly) {
        display: block;
      }
      li {
        margin: 0 15px 0 0;
        padding: 0;
        align-items: center;
        font-size: 12px;
        color: $secondary-black;
        img {
          width: 26px;
          margin: 10px 5px 0 0;
        }
      }
    }
    .jobsHead {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      border-bottom: 1px solid $primary-grey;
      padding-bottom: 10px;
      margin-bottom: 10px;
      .jobsHeadCol {
        &:nth-child(1) {
          width: 100%;
          @include breakpoint($mobileOnly) {
            width: 100%;
          }
        }
        .ant-select {
          color: $primary-orange;
        }
        .ant-select-single.ant-select-open .ant-select-selection-item {
          color: $primary-orange;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          border: 1px solid $primary-orange;
          border-radius: 10px;
        }
        .ant-select-arrow {
          color: $primary-orange;
        }
        li {
          margin: 0 25px 0 0;
          padding: 0;
          align-items: center;
          display: flex;
          font-size: 13px;
          color: $secondary-black;
        }
        .JobInfo {
          line-height: 1.4;
          span.infoNameType {
            display: block;
            font-size: 11px;
            font-style: italic;
          }
          span.infoNameValue {
            font-weight: 600;
          }
        }
        h3 {
          color: $primary-blue;
          margin: 0;
          small {
            display: block;
          }
        }
      }
    }
    .jobsInfo {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      @include breakpoint($mobileOnly) {
        display: block;
      }
      .jobsInfoCol {
        .buttons {
          li {
            @include breakpoint($mobileOnly) {
              margin: 2px;
            }
          }
        }
        &:nth-child(1) {
          width: 100%;
          @include breakpoint($mobileOnly) {
            width: 100%;
          }
        }
        &:nth-child(2) {
          width: 50%;
          text-align: right;
          display: inline-flex;
          justify-content: flex-end;
          @include breakpoint($mobileOnly) {
            margin-top: 10px;
            width: 100%;
            justify-content: start;
          }
          img {
            width: 30px;
          }
          a {
            span {
              text-decoration: none !important;
            }
          }
          span {
            border: 1px solid $primary-orange;
            border-radius: 5px;
            padding: 5px 15px;
            min-width: 80px;
            text-align: center;
            color: $secondary-black;
            cursor: pointer;
            font-size: 13px;
            font-weight: 600;
            box-shadow: 0px 3px 10px #0000000f;
            &:hover {
              color: $white;
              background-color: $primary-orange;
            }
          }
          .buttons {
            @include breakpoint($mobileOnly) {
              margin: 0px;
            }
          }
          ul {
            li {
              &:after {
                content: "";
                color: $primary-grey;
                position: relative;
                left: 0px;
                @include breakpoint($mobileOnly) {
                  display: none;
                }
              }
            }
          }
        }
        ul {
          @include breakpoint($mobileOnly) {
            display: flex;
          }
          li {
            @include breakpoint($mobileOnly) {
              margin: 0;
            }
            &:after {
              content: "|";
              color: $primary-grey;
              position: relative;
              left: 4px;
              @include breakpoint($mobileOnly) {
                display: none;
              }
            }
            &:last-child {
              &:after {
                content: "";
              }
            }
            span {
              padding: 0 5px 0 0;
              color: $secondary-black;
              font-weight: 500;
              font-size: 11px;
            }
          }
        }
        a span:first-child {
          text-decoration: underline;
          color: $secondary-black;
        }
      }
    }
  }
  .JPVHead {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-flow: row wrap;
      li {
        margin: 0;
        padding: 0 15px 0 0px;
        display: inline-flex;
        align-items: center;
        img {
          width: 20px;
          position: relative;
          top: 2px;
          margin-right: 5px;
        }
      }
    }
  }
  .descriptionHtml {
    padding: 15px;
  }
  .JPVSkills {
    ul {
      margin: 0;
      padding: 0 0 0 25px;
      // list-style: none;
      li {
        margin: 0;
        padding: 7px;
      }
    }
  }
  .JPVOtherInfo {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin: 0;
        padding: 7px;
        display: inline-flex;
        width: 33%;
        border-bottom: 1px solid #f7f7f7;
        @include breakpoint($tablet) {
          width: 50%;
        }
        @include breakpoint($mobileOnly) {
          width: 100%;
        }
        span:nth-child(1) {
          font-weight: 600;
          min-width: 150px;
        }
        span {
          display: inline-block;
        }
        &.languagesBox {
          .LangName {
            min-width: auto !important;
            font-weight: normal !important;
          }
          .comma {
            min-width: auto !important;
            font-weight: normal !important;
          }
        }
        &.languagesBox > span .comma:last-child {
          display: none;
        }
      }
    }
    &.fullRow {
      ul {
        li {
          width: 100%;
          border: 0;
        }
      }
    }
  }
  // .JPVOtherApplicantDetails {
  //   ul {
  //     margin: 0;
  //     padding: 0;
  //     list-style: none;
  //     li {
  //       margin: 0;
  //       padding: 7px;
  //       border-bottom: 1px solid #f7f7f7;
  //       span:nth-child(1) {
  //         font-weight: 600;
  //         min-width: 150px;
  //       }
  //       span {
  //         display: inline-block;
  //       }
  //       a{
  //         color: $primary-black;
  //       }
  //     }
  //   }
  // }
}
