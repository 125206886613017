@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.verifyEmail {
  .emailVerify {
    background-color: $secondary-grey;

    .emailVerifyHeader {
      height: 5rem;
      background-color: $white;
      text-align: center;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }

    .emailVerifyRow {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      padding: 50px;
      min-height: calc(100vh - 0px);

      @include breakpoint($mobileOnly) {
        display: block;
      }

      .emailVerifyCol {
        width: 50%;
        padding: 50px 50px 0;

        @include breakpoint($mobileOnly) {
          width: 100%;
          padding: 50px 0px 0;
        }

        &.imageLady {
          text-align: center;
        }

        img {
          max-width: 450px;
        }

        h2 {
          font-weight: 900;
          font-size: 52px;
          color: $primary-blue;
          line-height: 62px;
          text-shadow: 0 13.36px 8.896px #c4b59d, 0 -2px 1px #fff;
          @include breakpoint($mobileOnly) {
            font-size: 38px;
          }
        }

        p {
          font-size: 20px;
        }

        .emailVerifyBtn {
          box-shadow: 0px 7px 8px #ed841a73;
          border-radius: 13px;
          background-color: $primary-orange;
          border-color: $primary-orange;
          width: 175px;
          height: 60px;
          margin: 50px 0;
          font-size: 20px;
          line-height: 30px;
        }

        .backToLogin {
          a {
            color: $primary-red;
          }

          span {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
  }
}