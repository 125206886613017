@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.resumeView {
  padding: 15px;
  margin: 0;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  min-height: 300px;

  h3 {
    margin: 15px 0 15px;
    padding: 0 40px;

    @include breakpoint($mobileOnly) {
      padding: unset;
    }
  }

  .ant-modal {
    width: 800px !important;
  }

  .resumeInfo {
    background-color: #e7f3fc;
    /* box-shadow: 0px 3px 6px #00000017; */
    border-radius: 11px;
    padding: 15px;
    margin: 0 40px;
    font-size: 16px;

    @include breakpoint($mobileOnly) {
      margin: 0;
    }

    .pictureContainer {
      width: 200px;
      height: 200px;

      .listItem {
        height: 100%;
        margin: 0;
        position: relative;
        padding: 8px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;

        .listItemInfo {
          position: relative;
          height: 100%;
          overflow: hidden;
          padding: 0;

          span {
            display: block;
            width: 100%;
            height: 100%;

            img {
              position: static;
              display: block;
              width: 100%;
              height: 100%;
              -o-object-fit: contain;
              object-fit: contain;
            }
          }
        }
      }
    }

    p {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 7px 10px;
        display: flex;

        @include breakpoint($mobileOnly) {
          word-break: break-all;
        }

        &:nth-child(odd) {
          background-color: rgba(255, 255, 255, 0.27);
        }

        .head {
          display: inline-flex;
          width: 25%;
          font-weight: 600;

          @include breakpoint($mobileOnly) {
            width: 100%;
          }
        }

        .info {
          width: 75%;

          @include breakpoint($mobileOnly) {
            width: 100%;
          }


        }

        .camelCase {
          text-transform: lowercase;

          &:first-letter {
            text-transform: capitalize;
            display: block;
          }
        }
      }
    }

    .imgContainer {
      // padding: 30px !important;
      text-align: center;

      @include breakpoint($mobileOnly) {
        padding: 5px !important;
      }

      .imgRow {
        border: 10px solid #fff;
        padding: 10px;
        background: #f5f5f5;
        border-radius: 10px;
        box-shadow: 0px 3px 6px #00000017;
        // height: 200px;
        position: relative;

        .videoPlay {
          svg {
            position: absolute;
            top: 40%;
            left: 40%;
            cursor: pointer;
            font-size: 50px;
          }

        }


        video {
          width: 100%;
        }

        video::-webkit-media-controls {
          display: none;
        }
      }

      .ant-image {
        width: 100%;

        .ant-image-img {
          height: 230px;
        }
      }
    }
.workExperience{
  border-bottom: 2px solid #f7f7f7;
  padding: 0 !important;
  li{
   .head{
    width: 40%;
   }
   .info{
    width: 60%;
   }
  }
}
.workExperience:nth-child(odd) {
  border-right: 2px solid #f7f7f7;
  @include breakpoint($tablet) {
    border-right: unset;
  }
  @include breakpoint($mobileOnly) {
    border-right: unset;
  }
}
  }

  .studentPortFolio {
    padding: 40px;

    .workLinks {
      margin: 10px 0px;
      padding: 10px 0px;
      border-top: 1px solid $primary-grey;
      word-break: break-word;
      a {
        font-size: 20px;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  .resumeInfoWorkExperience {
    // background-color: #fae2c9;
    /* box-shadow: 0px 3px 6px #00000017; */
    border-radius: 11px;
    padding: 0px;
    margin: 0 40px;
    font-size: 16px;

    @include breakpoint($mobileOnly) {
      margin: 0;
    }

    ul {
      background-color: #fae2c9;
      margin: 0 0 20px;
      padding: 0;
      list-style: none;

      li {
        margin: 0;
        padding: 7px 20px;

        @include breakpoint($mobileOnly) {
          word-break: break-all;
        }

        &:nth-child(odd) {
          background-color: rgba(255, 255, 255, 0.27);
        }

        .head {
          display: inline-flex;
          width: 25%;
          font-weight: 600;

          @include breakpoint($mobileOnly) {
            width: 100%;
          }
        }

        .info {
          display: inline-flex;
          width: 75%;

          @include breakpoint($mobileOnly) {
            width: 100%;
          }
        }
      }
    }


  }

  .profileHead {
    background: #fff;
    position: relative;
    z-index: 9;
    padding: 30px 30px;
    display: flex;

    @include breakpoint($mobileOnly) {
      display: block;
      padding: 30px 30px 0px;
    }

    &:before {
      content: "";
      background-color: #2d83bf;
      width: 100%;
      left: 0;
      right: 0;
      position: absolute;
      z-index: -1;
      top: 0;
      height: 50%;
      border-radius: 10px;
    }

    .userImg {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 12px #00000082;
      border: 1px solid #e6e6e6;
      border-radius: 20px;
      width: 160px;
      height: 160px;
      padding: 7px;

      @include breakpoint($mobileOnly) {
        width: 100%;
        height: 100%;
      }
    }

    .userInfo {
      color: $white;
      padding: 0 30px;

      @include breakpoint($mobileOnly) {
        color: $black;
        padding: unset;
        text-align: center;
      }

      h2 {
        margin: 0;
        padding: 0;
        font-size: 30px;
        color: $white;

        @include breakpoint($mobileOnly) {
          color: $black;
        }
      }
    }
  }
}
.subscribeError{
  text-align: center;
  margin: 70px 0;
  padding: 20px;
  font-size: 30px;
  font-weight: 600;
}

.headingContainer{
  .headingButton{
    .admin-block{
      margin-right: 12px;
    }
  }
}
