@import "../../../assets/styles/colours";
@import "../../../assets/styles/responsive";

.jobOffered {
  &.home {
    .allJabsList {
      .jobsRow {
        margin: 0;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        padding-bottom: 10px;
      }
    }
  }
}

.renderJobOfferedList {
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  background-color: $white;
  padding: 0px 15px 15px;
  margin: 0px 0 0;
  h2{
    text-align: center;
    padding: 20px;
  }
  .offeredList {
    border-top: 1px solid $primary-grey;
    padding: 15px 0;
    display: flex;
    @include breakpoint($mobileOnly) {
      display: block;
    }
    // max-width: 75%;
    .applicantInfo {
      margin: 0;
      padding: 0px 10% 0 0;
      width: 80%;
      position: relative;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        border-right: 1px solid $primary-grey;
        z-index: -1;
        @include breakpoint($mobileOnly) {
          border: none;
        }
      }
      .applicantRow {
        display: flex;
        align-items: center;
        @include breakpoint($mobileOnly) {
          display: block;
        }
        .applicantCol {
          width: 50%;
          &:nth-child(1) {
            width: 50%;
            @include breakpoint($mobileOnly) {
              width: 100%;
            }
          }
          &:nth-child(2) {
            width: 30%;
            @include breakpoint($mobileOnly) {
              width: 100%;
            }
          }
          &:nth-child(3) {
            width: 20%;
            @include breakpoint($mobileOnly) {
              width: 100%;
            }
          }
          .applicantInfoRow {
            display: flex;
            .applicantInfoCol {
              width: 50%;
              padding: 7px 0;
              &:first-child {
                font-weight: 500;
                width: 25%;
              }
              &:last-child {
                width: 75%;
              }
            }
          }
        }
      }
      h3 {
        small {
          color: $primary-green;
          margin-left: 20px;
          font-weight: 700;
        }
      }
    }
    .applicantRating {
      margin: 0;
      padding: 0 0 0 30px;
      width: 20%;
      @include breakpoint($mobileOnly) {
        width: 100%;
        padding: 0px 10px;
      }
      p {
        strong {
          color: $primary-green;
        }
      }
    }
    .viewResumeBox {
      a , button{
        background-color: transparent;
        border: 1px solid $primary-orange;
        color: $secondary-black;
        border-radius: 5px;
        padding: 7px 10px;
        cursor: pointer;
        min-width: 106px;
        display: inline-block;
        text-align: center;
        font-weight: 500;
        font-size: 12px;
        margin: 3px 5px 3px 0px;
        &:hover {
          background-color: $primary-orange;
          color: $white;
        }
      }
    }
  }
}
