@import "../../../assets/styles/colours";
@import "../../../assets/styles/responsive";
.UpdateResetPassword {
  .forgetPassword {
    background-color: $secondary-grey;
    .forgetPasswordHeader {
      height: 5rem;
      background-color: $white;
      text-align: center;
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
    }
    .forgetPasswordRow {
      display: flex;
      justify-content: center;
      flex-flow: row wrap;
      padding: 50px;
      min-height: calc(100vh - 0px);
      @include breakpoint($mobileOnly) {
        display: block;
        padding: 50px 0;
      }
      .forgetPasswordCol {
        width: 50%;
        padding: 50px 50px 0;
        @include breakpoint($mobileOnly) {
          width: 100%;
          padding: 40px 30px 0;
        }
        &.imageLady {
          text-align: center;
        }
        img {
          max-width: 450px;
        }
        h2 {
          font-weight: 900;
          font-size: 52px;
          color: $primary-blue;
          line-height: 62px;
          text-shadow: 0 13.36px 8.896px #c4b59d, 0 -2px 1px #fff;
        }
        p {
          font-size: 20px;
        }
        .forgetPwdRow {
          input {
            border: 0;
            border-bottom: 5px solid $third-grey;
            max-width: 500px;
            width: 100%;
            height: 60px;
            font-size: 20px;
            line-height: 30px;
            background: transparent;
            padding: 0;
            &:hover {
              background-color: $secondary-grey;
              border-color: $third-grey;
            }
            &::placeholder {
              /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: $third-grey;
              opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              color: $third-grey;
            }

            &::-ms-input-placeholder {
              /* Microsoft Edge */
              color: $third-grey;
            }
          }
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            box-shadow: 0 0 0 30px $secondary-grey inset !important;
          }
          .ant-input:focus,
          .ant-input-focused {
            box-shadow: none;
          }
          .ant-form-item-explain-error {
            color: $primary-red;
          }
        }
        .forgetPasswordBtn {
          box-shadow: 0px 7px 8px #ed841a73;
          border-radius: 13px;
          background-color: $primary-orange;
          border-color: $primary-orange;
          width: 175px;
          height: 60px;
          margin: 50px 0;
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
}
