@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.adminHome {
  .profile {
    .profileCard {
      background-color: $white;
      height: auto;
      width: 100%;
      box-shadow: 0px 3px 6px #00000017;
      border-radius: 11px;
      padding: 0 28px;
      @include breakpoint($mobileOnly) {
        height: auto;
        width: 100%;
      }
      .profileImage {
        .imageConatainer {
          margin: 40px 0 20px;
          @include breakpoint($mobileOnly) {
            // padding-left: 18px;
          }
          .imageRow {
            width: 100%;
            max-width: 300px;
            margin: 0 auto;
            background-color: #c1c1c1;
            border: 4px solid #c1c1c1;
            border-radius: 10px;
            .imageSize {
              width: 100%;
              border-radius: 10px;
            }
          }
        }
        p {
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          padding-top: 10px;
        }
      }

      .profileSection {
        padding: 28px 0px 25px;
        @include breakpoint($mobileOnly) {
          padding: 10px 0px 25px;
        }
        .ProfileTitle {
          h2 {
            color: $primary-blue;
            font-weight: 600;
            margin-bottom: 2px;
            letter-spacing: 1px;
          }
        }
        .companyProfile {
          display: flex;
          flex-flow: row wrap;

          .companyProfileHead {
            font-size: 13px;
            font-weight: 600;
            width: 40%;
            padding: 7px 0px;
            @include breakpoint($mobileOnly) {
              width: 100%;
              padding: 10px 0 0 0;
            }
          }
          .textOverFlow {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .companyProfileDetail {
            font-size: 13px;
            width: 60%;
            padding: 3px 0px;
            @include breakpoint($mobileOnly) {
              width: 100%;
              word-break: break-all;
              padding: 0;
            }
          }
        }
        .ProfileFeatures {
          .kycActiveStatus{
            color: $primary-green;
          }
          h3 {
            margin-bottom: 4px;
          }
        }
        .profileRow {
          padding-top: 10px;
          .profileDescription {
            p {
              margin-bottom: 4px;
              font-size: 14px;
              font-weight: 700;
              @include breakpoint($mobileOnly) {
                padding-top: 4px;
                font-size: 12px;
              }
            }
          }
          .profileValue {
            p {
              margin-bottom: 4px;
              @include breakpoint($mobileOnly) {
                padding-top: 3px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
.editProfile {
  .profile {
    // margin: 10px 0 20px;
    margin-bottom: 20px;
    background-color: $white;
    height: auto;
    width: 100%;
    box-shadow: 0px 3px 6px #00000017;
    border-radius: 11px;
    padding: 0 24px 0px 28px;
    .signatureBox {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
      .signatureBoxDisplayProfile {
        background-color: #c1c1c1;
        border: 4px solid #c1c1c1;
        border-radius: 10px;
        margin: 20px 0 20px;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .signatureBoxProfile {
        position: relative;
        label {
          position: absolute;
          background-color: #ed9f0b;
          width: 100%;
          height: 40px;
          border: aliceblue;
          cursor: pointer;
          color: #f2f2f2;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        input {
          position: relative;
          z-index: 1;
          opacity: 0;
          width: 100%;
          height: 40px;
          cursor: pointer;
        }
        p{
          color: $primary-red;
        }
      }
    }
    .signatureBox {
      .imageConatainer {
        padding-top: 30px;
        @include breakpoint($mobileOnly) {
          padding-left: 18px;
        }
        .imageRow {
          border-radius: 14px;
          height: 188px;
          padding: 4px;
          width: 200px;
          background-color: $third-grey;
          position: relative;
          .profilePointer {
            cursor: pointer;
          }
          .fileUpload {
            display: none;
          }
          .imageSize {
            height: 180px;
            width: 100%;
            border-radius: 14px;
          }
        }
      }
      p {
        font-size: 14px;
        font-weight: 600;
        padding-top: 40px;
        margin-bottom: 0;
      }
    }
    .editProfileForm {
      padding: 30px 30px 25px 0;
      @include breakpoint($mobileOnly) {
        padding: 24px 1px 8px 1px;
      }
      .profileFormItems {
        .ant-form-item {
          margin-bottom: 10px;
        }
        .formLabel {
          font-size: 14px;
          font-weight: 700;
          .formInput {
            border-radius: 7px;
            box-shadow: 0px 0px 5px #00000033;
            opacity: 1;
          }
          .contactNumber {
            width: 100%;
          }
        }

        .textArea {
          border-radius: 7px;
          height: 80px;
          box-shadow: 0px 0px 5px #00000033;
          opacity: 1;
        }
        .saveBtn {
          margin: 26px 0 10px 0;
          .formButton {
            float: right;
          }
        }
      }
    }
  }
}
