@import "../../../assets/styles/colours";
.headerWithOutAuth {
  height: 5rem;
  background-color: $white;
  text-align: center;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  position: fixed;
  z-index: 9;
  top: 0;
}
