@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.registerEvent {
  .tag {
    position: absolute;
    top: -1.5px;
    left: -3.5px;
  }
  .event-list {
    display: flex;
    flex-wrap: wrap;
    // justify-content: center;
  }

  @media (max-width: 1500px) {
    .event-list {
      grid-template-columns: repeat(2, 1fr);
    }

    .event-card {
      width: calc(50% - 20px);
    }

    .banner {
      width: calc(100%);
    }
  }

  @media (min-width: 1501px) {
    .event-card {
      width: calc(33.33% - 20px);
      /* 3 cards per row */
    }

    .banner {
      width: calc(100%);
    }
  }

  @media (max-width: 1040px) {
    .event-card {
      width: calc(100% - 20px);
      /* 3 cards per row */
    }

    .banner {
      width: calc(100%);
    }
  }

  .event-card {
    position: relative;
    margin: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    min-height: 300px;
    min-width: 100px;
  }

  .event-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ed9f0b;
    height: 50px;
  }

  .menu {
    cursor: pointer;
  }

  .menu-options {
    position: absolute;
    top: 30px;
    right: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .card-content {
    position: relative;
    // padding: 10px 20px 20px;
    margin-top: 80px;
  }

  .date {
    //   font-weight: bold;
    color: #393939;
    font-size: 15px;
  }

  .name {
    //   margin-top: 5px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #2d83bf;
}
  .about-company-icon {
    color: #ed9f0b;
  }

  .type {
    font-weight: bold;
    color: #2d83bf;
  }

  .details-of-event {
    margin-left: 80px;
    margin-top: 40px;
}
.start-date {
    
    font-size: 14px;
    color: #333;
    font-weight: bold; /* Make "Starts from" bold */
  }
  
 
  .end-date {

    font-size: 14px;
    color: #333;
    font-weight: bold;
  }

  .heading {
    color: #2c4394;
    font-size: 32px;
    margin: 0;
    font-weight: 700;
  }

  .header-events {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin: 10px 0 20px;
    justify-content: space-between;
  }

  .event-button {
    color: #fff;
    background-color: #ed9f0b;
    border-color: #ed9f0b;
    box-shadow: 0 3px 6px rgba(237, 132, 26, 0.4705882353);
    border-radius: 8px;
    font-size: 16px;
    font-weight: 400;
    padding: 5px 15px !important;
    height: auto;
    min-width: 100px;
    text-align: center;
  }

  .banner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background-color: #f0f0f0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-container {
    position: absolute;
    top: 120px;
    left: 10px;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;
    z-index: 1;
  }

  .logo {
    width: 100%;
    height: 100%;
  }

  .card-content {
    padding: 60px 20px 20px;
  }

  .register-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    .registered {
      width: 80px;
      min-width: 80px;
      display: flex;

      // align-items: flex-end;
      color: #ed9f0b;
      font-size: 12px;
      height: 20px;
      justify-content: center;
      border-color: #ed9f0b;
      box-shadow: 0 0 0 2px #ed9f0b;
      border-right-width: 1px;
      border-radius: 5px;
    }
    .Approved {
      width: 80px;
      min-width: 80px;
      display: flex;
      // cursor:alias;
      // align-items: flex-end;
      color: green;
      font-size: 12px;
      height: 20px;
      justify-content: center;
      border-color: green;
      box-shadow: 0 0 0 2px green;
      border-right-width: 1px;
      border-radius: 5px;
    }
    .reject {
      width: 80px;
      min-width: 80px;
      display: flex;
      // cursor:alias;
      // align-items: flex-end;
      color: red;
      font-size: 12px;
      height: 20px;
      justify-content: center;
      border-color: red;
      box-shadow: 0 0 0 2px red;
      border-right-width: 1px;
      border-radius: 5px;
    }
    .view-all-link {
      width: 80px;
      min-width: 80px;
      display: flex;
      cursor: pointer;
      // align-items: flex-end;
      color: #2d83bf;
      font-size: 12px;
      height: 20px;
      justify-content: center;
      border-color: #2d83bf;
      box-shadow: 0 0 0 2px #2d83bf;
      border-right-width: 1px;
      border-radius: 5px;
    }
  }
  .ant-modal-content {
    border-radius: 15px;
    .ant-modal-header {
      border-radius: 15px 15px 0 0;
    }
  }
  .pay-card {
    display: flex;
    // justify-content: space-between;
    // align-items: center;
    // background-color: #f0f0f0;
    // padding: 10px 20px;
    flex-direction: row-reverse;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    .price {
      font-weight: bold;
      font-size: 18px;
      //   color: #333;
    }

    .buy-now-button {
      margin-left: auto;
    }
  }
}
