@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";
.star-rating {
  display: flex;
  flex-wrap: wrap;
  height: 34px;
  align-items: flex-start;
  margin-bottom: 15px;
}

.star {
  cursor: pointer;
  width: 1.5em;
  height: 1.5em;
  background-color: grey;
  -webkit-clip-path: polygon(50% 0%, 63% 38%, 100% 38%, 69% 59%, 82% 100%, 50% 75%, 18% 100%, 31% 59%, 0% 38%, 37% 38%);
  clip-path: polygon(50% 0%, 63% 38%, 100% 38%, 69% 59%, 82% 100%, 50% 75%, 18% 100%, 31% 59%, 0% 38%, 37% 38%);
}

.star.selected {
  background-color: $primary-orange;
}
.starRating {
  text-align: center;
  margin-right: 15px;
    @include breakpoint($tablet) {
      margin-right: 12px;
}
}
span.ratingNo {
  font-size: 10px;
}
