// EventDetailsModal.scss

$primary: #ed9f0b;
$text: #333;

h3 {
  color: $primary ;
  font-size: 1.2rem;
}

p {
  color: $text;
  line-height: 1.5;
}

.event-details-modal {
  .ant-modal-content {
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .ant-modal-header {
    background: $primary;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .ant-modal-title {
      color: #fff !important;
    }
  }
}

.event-details-content {
  .event-detail-row {
    padding: 10px 0;
    border-bottom: 1px solid #eee;

    &:last-child {
      border-bottom: 0;
    }
  }

  .event-info-label {
    font-weight: bold;
  }
}

.anticon-info-circle {
  color: $primary;
}
