@import "~toastr/toastr";

#toast-container > div {
  top: 5px;
  opacity: 1 !important;
  width: 100%;
  min-width: 200px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  padding: 10px 10px 10px 40px;
  box-shadow: 0 0 12px -5px #ed9f0b;
}

#toast-container > .toast-success {
  background-image: url("../../assets/images/icon_success.svg") !important;
  background-repeat: no-repeat;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #f4f4f4;
  box-shadow: 0 0 12px -5px #0bd50b;
  background-position: 10px 12px;
  background-size: 20px;
  .toast-close-button {
    color: #000 !important;
    position: absolute;
    top: -5px;
    right: 5px;
  }
  .toast-progress {
    background-color: #0bd50b;
    opacity: 1;
  }
}

#toast-container > .toast-error {
  background-image: url("../../assets/images/icon_error.svg") !important;
  background-repeat: no-repeat;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #f4f4f4;
  box-shadow: 0 0 12px -5px #eb1a1a;
  background-position: 10px 12px;
  background-size: 20px;
  .toast-close-button {
    color: #000 !important;
    position: absolute;
    top: -5px;
    right: 5px;
  }
  .toast-progress {
    background-color: #eb1a1a;
    opacity: 1;
  }
}

#toast-container > .toast-info {
  // background-image: url("../../assets/images/icon_info.svg") !important;
  background-repeat: no-repeat;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #f4f4f4;
  box-shadow: 0 0 12px -5px #506eca;
  background-position: 10px 12px;
  background-size: 20px;
  .toast-close-button {
    color: #000 !important;
    position: absolute;
    top: -5px;
    right: 5px;
  }
  .toast-progress {
    background-color: #506eca;
    opacity: 1;
  }
}

#toast-container > .toast-warning {
  // background-image: url("../../assets/images/icon_warning.svg") !important;
  background-repeat: no-repeat;
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #f4f4f4;
  box-shadow: 0 0 12px -5px #ed9f0b;
  background-position: 10px 12px;
  background-size: 20px;
  .toast-close-button {
    color: #000 !important;
    position: absolute;
    top: -5px;
    right: 5px;
  }
}
#toast-container > div:hover {
  box-shadow: 0 0 12px -5px #ed9f0b;
}
.toast-top-center {
  top: 145px;
}

#toast-container > .toast-success,
#toast-container > .toast-error,
#toast-container > .toast-info,
#toast-container > .toast-warning {
  .toast-close-button {
    border: 0px solid;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    width: 18px;
    padding: 0;
    margin: 0px;
    font-size: 16px;
    position: relative;
    right: 0;
    top: 2px;
  }
}
button.toast-close-button:after {
  content: "";
  border: 2px solid;
  width: 19px;
  height: 19px;
  position: absolute;
  top: 0.5px;
  border-radius: 10px;
}
