@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.formItems {
    background-color: $white;
    width: 100%;
    border-radius: 15px;
    padding: 30px 30px;
    .kycCard {
        max-width: 600px;
        margin: 20px auto;
        input {
            height: 45px;
            box-shadow: none;
            border-radius: 7px;
            margin: 4px 0 0 0;
        }
        label {
            font-weight: 600;
        }
        .KycButton {
            margin-top: 28px;
            .KycPendingButton {
                top: 5px;
                border-radius: 10px;
                height: 45px;
            }
            @include breakpoint($mobileOnly) {
                margin-top: 0;
            }
        }
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
            height: 45px;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            width: 100%;
            height: 45px;
            padding: 6px 11px;
            border-radius: 7px;
        }
        .actionBtn {
            margin-top: 20px;
            .ant-btn[disabled], .ant-btn[disabled]:active, .ant-btn[disabled]:focus, .ant-btn[disabled]:hover {
                color: rgba(0,0,0,.25) !important;
                border-color: #d9d9d9 !important;
                background: #d6d4d4 !important;
                text-shadow: none !important;
                box-shadow: none !important;
            }
        }
        .kycPolicyLink{
            color: #560cab;
            cursor: pointer;
            font-weight: 500;
        }
    }
    .submitBuuton {
        margin: 26px 0 40px 0;
        @include breakpoint($mobileOnly) {
            margin: 20px 0 30px 0;
        }
        .actionBtn {
            text-align: end;
            @include breakpoint($mobileOnly) {
                text-align: left;
            }
        }
    }
}
.isKycVerified{
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    @include breakpoint($mobileOnly){
    top: 60%;
    width: 100%;
    }
    .ant-image{
        .ant-image-img{
           height: 100px;
           width: unset !important;
        }
    }
    .kycSuccess{
        h1{
            margin-top: 40px;
        }
        p{
            font-size: 17px;
        }
    }
}