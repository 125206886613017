@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.candidatesListTable {
  .ant-table table {
    border-radius: 0px 0px 0 0;
    border-spacing: 0px 7px;
    background: $secondary-grey;
    tr {
      td {
        &:nth-child(1) {
          width: 20%;
        }
        &:nth-child(2) {
          width: 32%;
          @include breakpoint($tablet) {
            width: 25%;
        }
        }
        &:nth-child(3) {
          width: 18%;
          @include breakpoint($tablet) {
            width: 25%;
        }
        }
        &:nth-child(4) {
          width: 30%;
          @include breakpoint($tablet) {
            width: 30%;
        }
        }
      }
    }
  }
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > td {
    padding: 7px;
    background: $white;
    border-color: $white;
  }
  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > th {
    padding: 7px;
    background: $secondary-grey;
    border-color: $secondary-grey;
  }
  .ant-table-tbody > tr > td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .ant-table-tbody > tr > td:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
  .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0px;
  }
  .ant-btn.ant-btn-primary {
    margin: 0 5px;
    min-width: 133px;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0px 3px 10px #0000000f;
    border-radius: 5px;
    line-height: 18px;
    @include breakpoint($tablet) {
      margin: 10px 0 0 0;
  }
    @include breakpoint($mobileOnly) {
      margin: 8px 0 0;
      min-width: 102px;
  }
  }
  .jobSeekerActionBtn{
  a {
    border: 1px solid #ed9f0b;
    border-radius: 5px;
    padding: 5px 15px;
    text-align: center;
    color: #393939;
    cursor: pointer;
    font-size: 13px;
    font-weight: 600;
    box-shadow: 0px 3px 10px #0000000f;
    min-width: 133px;
    @include breakpoint($mobileOnly) {
      padding: 5px 14px;
    }
    &:hover {
      color: #ffffff;
      background-color: #ed9f0b;
    }
  }
}
  .btnNotified {
    background-color: #e09b7b !important;
    border-color: #e09b7b !important;
    color: rgba(255, 255, 255, 0.7) !important;
    &:hover {
      background-color: #e09b7b !important;
      border-color: #e09b7b !important;
    }
  }
  .btnApplied {
    background-color: #d3b9b0 !important;
    border-color: #d3b9b0 !important;
    color: rgba(255, 255, 255, 0.7) !important;
    &:hover {
      background-color: #d3b9b0 !important;
      border-color: #d3b9b0 !important;
    }
  }
  .btnNotInterested {
    background-color: #745796 !important;
    border-color: #745796 !important;
    color: rgba(255, 255, 255, 0.5) !important;
    &:hover {
      background-color: #745796 !important;
      border-color: #745796 !important;
    }
  }
  .jobseekerStarRating{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    .starRating{
      margin-right: 8px;
    }
  }
  .star {
    cursor: default;
  }
  .textOverFlow{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; 
           line-clamp: 2; 
   -webkit-box-orient: vertical;
  }
}
.searchRow {
  margin: 15px 0 30px;
  
 

  input {
    height: 45px;
    box-shadow: none;
    border-radius: 7px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-btn.ant-btn-primary {
    height: 45px;
  }
  @include breakpoint($mobileOnly) {
    .ant-btn.ant-btn-primary {
      min-width: 50px;
  }
  }
   
}
.filterSearchContainer {
  text-align: end;
  margin-top: 28px;

  @include breakpoint($mobileOnly) {
    text-align: end;
    margin-top: 30px;
  }

  button {
    min-width: 66px !important;
    padding: 20px !important;
    color: $white;
    background-color: $primary-orange;
    border-color: $primary-orange;
    box-shadow: 0px 3px 6px $primary-orange;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 300;
    height: auto;
    text-align: center;

    @include breakpoint($mobileOnly) {
      min-width: 50px !important;
      padding: 9px !important;
    }
  }

  .anticon-control {
    font-size: 24px !important;
  }
}
.jobSeekerRow {
  .ant-select {
    width: 100%;
    height: 45px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 45px;
    border-radius: 7px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 45px;
    transition: all 0.3s;
  }
  .jobSeekerSearchButton{
    min-width: 100px;
    @include breakpoint($tablet) {
      margin-left: 14px;
    }
  }
}

.commonFilter{
  .jobseekerRating{
    margin-top: 24px;
  }
}