@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.loginPage {
  background-image: url("../../assets/images/LoginPageBanner.png");
  height: 100vh;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: top left;
  position: relative;
  .loginContainer {
    position: relative;
    .loginRow {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: center;
      height: calc(100vh - 42px);
      @include breakpoint($mobileOnly) {
        display: block;
      }
      .loginCol {
        width: 50%;
        @include breakpoint($tablet) {
          width: 48%;
        }
        @include breakpoint($mobileOnly) {
          width: 100%;
          padding-top: 90px;
        }
        .loginLogo {
          text-align: center;
          margin: 25px 0 20px 0;
          @include breakpoint($mobileOnly) {
            margin: 35px 0 0 0;
          }
        }
        .loginRoleBox {
          p {
            text-align: center;
            font-size: 16px;
            line-height: 25px;
            font-weight: 600;
          }
          .loginRole {
            text-align: center;
            .ant-radio-button-wrapper {
              width: 126px;
              height: 89px;
              margin: 0 15px;
              color: $primary-black;
              border: 3px solid $primary-grey;
              border-radius: 20px;
              background-image: url("../../assets/images/icons/Employer.svg");
              background-position: center center;
              background-repeat: no-repeat;
              &:hover {
                border-color: $primary-blue;
                background-color: $primary-blue;
                background-image: url("../../assets/images/icons/EmployerHover.svg");
              }
              span:last-child {
                position: relative;
                top: 90px;
              }
            }
            .ant-radio-button-wrapper:first-child {
              border: 3px solid $primary-grey;
              border-radius: 20px;
              background-image: url("../../assets/images/icons/Admin.svg");
              background-position: center center;
              background-repeat: no-repeat;
              &:hover {
                border-color: $primary-blue;
                background-color: $primary-blue;
                background-image: url("../../assets/images/icons/AdminHover.svg");
              }
            }
            .ant-radio-button-wrapper:last-child {
              border: 3px solid $primary-grey;
              border-radius: 20px;
              background-image: url("../../assets/images/icons/Merchant.svg");
              background-position: center center;
              background-repeat: no-repeat;
              &:hover {
                border-color: $primary-blue;
                background-color: $primary-blue;
                background-image: url("../../assets/images/icons/MerchantHover.svg");
              }
            }
            .ant-radio-button-wrapper:not(:first-child)::before {
              width: 0px;
            }
            .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
              background-color: $primary-blue;
              border-color: $primary-blue;
            }
            
          }
        }
        .loginFormBox {
          max-width: 500px;
          width: 100%;
          margin: 20px auto 0px;
          @include breakpoint($mobileOnly) {
            padding: 20px;
            margin: 10px auto 0px;
          }
          .login-email {
            border: 1px solid $primary-orange;
            border-radius: 10px;
            height: 40px;
            width: 100%;
            margin-bottom: 12px;
            padding: 15px;
          }
          .ant-input-affix-wrapper {
            height: 40px;
            border: 1px solid $primary-orange;
            border-radius: 10px;
          }
          .ant-input:focus,
          .ant-input-focused {
            box-shadow: none;
          }
          .ant-input-affix-wrapper:focus,
          .ant-input-affix-wrapper-focused {
            box-shadow: none;
          }
          .loginBtn {
            border: 1px solid $primary-orange;
            border-radius: 10px;
            background-color: $primary-orange;
            color: $white;
            height: 40px;
            margin-top: 20px;
            width: 100%;
            cursor: pointer;
          }
          .login-form-forgot {
            color: #97BFDC;
            text-align: right;
            display: block;
            margin: 20px 0;
            @include breakpoint($mobileOnly) {
              text-align: center;
            }
          }
          .submitRow {
            text-align: center;
            position: relative;
            .loadingSpin {
              position: absolute;
              left: 0;
              right: 0;
              top: 30px;
            }
          }
          .help-block{
            color: $primary-red;
          }
        }
        .registerBox {
          margin: 40px 0;
          text-align: center;
          @include breakpoint($mobileOnly) {
            margin: 10px 0;
          }
          .registerLink {
            color: #97BFDC;
            text-decoration: underline;
          }
          p {
            font-weight: 600;
          }
        }
      }
    }
  }
  .loginCopyRight {
    text-align: center;
    p {
      padding: 0px;
      margin: 0;
    }
  }
  .redMandatory {
    color: $primary-red;
    text-align: center;
    position: relative;
    top: 10px;
  }
  .loginRoleBox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
  }
  .loginRoleCol {
    width: 50%;
    text-align: center;
    padding: 15px;
    background-color: rgba(246, 246, 246, 0.2);
    border-bottom: 2px solid #ed9f0b;
    font-size: 18px;
    font-weight: 500;
    color: #ed9f0b;
    border-width: thick;
    @include breakpoint($mobileOnly) {
      font-size: 17px;
      padding: 5px;
    }
  }
  .loginRoleCol:nth-child(1) {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .loginRoleCol:nth-child(2) {
    border-bottom: 2px solid #837f7b;
    // border-top-right-radius: 10px;
    // border-bottom-right-radius: 10px;
    border-width: thick;
    &:hover {
      border-color: #ed9f0b;
      a {
        color: #ed9f0b;
      }
    }
    a {
      color: #837f7b;
      display: block;
    }
  }
  .loginRoleCol:nth-child(3) {
    border-bottom: 2px solid #837f7b;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-width: thick;
    &:hover {
      border-color: #ed9f0b;
      a {
        color: #ed9f0b;
      }
    }
    a {
      color: #837f7b;
      display: block;
    }
  }
}
