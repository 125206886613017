@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.ant-modal-title {
    font-size: 1.4rem !important;
    font-weight: bold !important;
    color: #2C4394 !important;
  }
  .custom-subscriptions {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    gap: 20px; /* Add gap between cards */
    .subscriptionCurrentPlanBox {
        ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                margin: 0;
                padding: 5px 0;
                font-size: 16px;

                p {
                    margin: 0;
                    color: #b5b5b5;
                    font-size: 14px;
                    font-style: italic;
                }

                strong {
                    margin-right: 10px;
                }
            }
        }

        &.histroy {
            border-bottom: 2px solid #f2f2f2;
            margin: 15px 0;
            padding-bottom: 15px;

            &:last-child {
                border: 0;
            }
        }

        
    }
}

.subscription-card {
    position: relative;
    border: 1px solid #e2e2e2;
    padding: 2%;
    font-size: 14px;
    font-weight: 400;
    background-color: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
    border-radius: 7px;
    flex: 0 1 calc(33.33% - 20px); /* Adjust card width for responsiveness */
}

@media (max-width: 768px) {
    .subscription-card {
        flex: 0 1 calc(50% - 20px); /* Two cards per row on medium screens */
    }
}

@media (max-width: 480px) {
    .subscription-card {
        flex: 0 1 calc(100% - 20px); /* Single card per row on small screens */
    }
}

.coupon{
    margin-bottom: 10px;
 }

.pricingDetails{
    
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
    table {
        width: 100%;
        border-collapse: collapse;
        // margin-top: 20px; /* Add margin or adjust as needed */
    
        th, td {
          border: 1px solid #e2e2e2;
          padding: 8px;
          text-align: left;
        }
    
        th {
          background-color: #f2f2f2; /* Add background color or adjust as needed */
        }
    
        tbody tr:nth-child(odd) {
          background-color: #f9f9f9; /* Add background color or adjust as needed for odd rows */
        }
    
        tbody tr:hover {
        //   background-color: #e6f7ff; /* Add background color or adjust as needed for hover effect */
        }
      }
    
}
.customSubscription{
    display: flex;
    flex-direction: column;
    // gap:10px;
    margin-top: 20px;
    
    label {
        margin-bottom: 8px;
        display: block;
        font-weight: bold;
        // color: #2C4394;
        color: #2C4394 !important;
      }
    //   .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    //     display: flex;
    //     flex-direction: row; 
    //     width: 16.8rem;
    //     margin-left: auto;
    //   }
      .ant-input{
        display: flex;
        flex-direction: row; 
        // text-align: right;
        width: 16.8rem;
        margin-left: auto;
        // justify-content: ;
      }
  
      .ant-select,
      .ant-input-number,
      .ant-btn-primary {
        margin-bottom: 16px;
      }

}
.totalAmount {
    margin-top: 20px;
    font-weight: bold;
    font-size: 1.2rem;
    color: #2C4394
;
.discountPrice{
    font-size: 1rem;
    text-decoration: line-through;
    margin-left: 5px;
    margin-right: 5px;
}
  }
 
  
.summerBonanzaBanner {
    background-color: #e26442; /* Background color f3bb42 */
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Box shadow */
    position: relative;
    animation: fadeIn 1s ease, pulseGlow 2s infinite alternate; /* Fade-in animation and pulsating glow */

    h3 {
        margin: 0;
        font-size: 23px;
        color: #fff;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Text shadow */
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 20px;
        height: 20px;
        background-color: transparent;
        transform: translateX(-50%) rotate(45deg);
    }

    &::before {
        top: -10px;
    }

    &::after {
        bottom: -10px;
    }

    @media screen and (max-width: 768px) {
        padding: 15px;
        
        h3 {
            font-size: 24px;
        }

        &::before,
        &::after {
            width: 15px;
            height: 15px;
        }
    }
}


.subscriptionPlansRow {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 50px;

    @include breakpoint($mobileOnly) {
        display: block;
    }

    .subscriptionPlansCol {
        width: 15.66%;
        border: 1px solid #e2e2e2;
        padding: 1% 1% 3%;
        font-size: 14px;
        font-weight: 400;
        margin: 0.5%;
        background-color: $white;
        box-shadow: 0px 3px 10px #0000000f;
        border-radius: 7px;

        @include breakpoint($tablet) {
            width: 32%;
        }

        @include breakpoint($mobileOnly) {
            width: 100%;
            padding: 4%;
            margin: 4% 0.5%;
        }

        .subscriptionPlanName {
            color: $primary-blue;
            font-size: 1.6rem;
            margin: 0 0px 30px;
            font-weight: 700;
            text-shadow: 0px 1px 1px #dadada;
        }

        .subscriptionPlanPrice {
            margin: 0px 0px 0px;
            font-family: Sailec-Regular, Helvetica, sans-serif;
            font-size: 2.4rem;
            color: #4d4d4d;
            font-weight: 900;
        }

        .subscriptionPlanValidityPeriod {
            margin: 0px 0px 40px;
            color: #4d4d4d;
            font-weight: 700;
            margin-left: 5px;
        }

        .subscriptionPlanNoOfListings {
            margin: 0px 0px 10px;
        }

        .subscriptionPlanNoOfResumeViews {
            margin: 0px 0px;
        }

        .subscriptionPlanButton {
            text-align: center;
            margin: 50px 0 0;
        }
    }

    span.anticon.anticon-check {
        color: $primary-blue;
        margin-right: 10px;
    }
}

.gstInvoice {
    background: $white;
    padding: 30px 15px;

    p {
        margin-bottom: 0;
        font-size: 16px;
        font-style: italic;
    }
}

.subscriptionCurrentPlans {
    display: flex;
    margin-bottom: 30px;

    @include breakpoint($mobileOnly) {
        display: block;
    }

    .subscriptionHistory {
        width: 50%;
        padding: 15px;

        @include breakpoint($tablet) {
            padding: 10px 6px;
        }

        &:nth-child(1) {
            background-color: $primary-orange;
            width: 25%;
            color: $white;
            font-size: 30px;
            font-weight: 700;
            letter-spacing: 1.2px;
            padding-top: 4%;
            text-align: center;

            span {
                display: block;
            }

            @include breakpoint($tablet) {
                width: 35%;
                font-size: 26px;
            }

            @include breakpoint($mobileOnly) {
                width: 100%;
            }
        }

        &:nth-child(2) {
            background-color: $white;
            width: 55%;
            padding-left: 5%;

            @include breakpoint($tablet) {
                width: 35%;
                padding-left: 12px;
            }

            @include breakpoint($mobileOnly) {
                width: 100%;
            }
        }

        &:nth-child(3) {
            background-color: $white;
            width: 20%;

            .downloadInvoice {
                padding: 61px 0px;
                text-align: center;
            }

            @include breakpoint($tablet) {
                width: 30%;
            }

            @include breakpoint($mobileOnly) {
                width: 100%;

                .downloadInvoice {
                    padding: 0 0 20px 0;
                    text-align: center;
                }
            }
        }

        .subscriptionCurrentPlanBox {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    margin: 0;
                    padding: 5px 0;
                    font-size: 16px;

                    p {
                        margin: 0;
                        color: #b5b5b5;
                        font-size: 14px;
                        font-style: italic;
                    }

                    strong {
                        margin-right: 10px;
                    }
                }
            }

            &.histroy {
                border-bottom: 2px solid #f2f2f2;
                margin: 15px 0;
                padding-bottom: 15px;

                &:last-child {
                    border: 0;
                }
            }

            .subscriptionInActiveUser {
                padding: 36px 0;
                margin: 10px 0;
            }
        }

        .histroyLink {
            margin: 0;
            padding: 0;
            text-align: right;

            a {
                color: $primary-blue;
            }
        }
    }

    .subscriptionCurrentPlanCol {
        width: 50%;
        padding: 15px;

        &:nth-child(1) {
            background-color: $primary-orange;
            width: 25%;
            color: $white;
            font-size: 30px;
            font-weight: 700;
            letter-spacing: 1.2px;
            padding-top: 4%;
            text-align: center;

            span {
                display: block;
            }

            @include breakpoint($tablet) {
                width: 40%;
            }

            @include breakpoint($mobileOnly) {
                width: 100%;
            }
        }

        &:nth-child(2) {
            background-color: $white;
            width: 75%;
            padding-left: 5%;

            @include breakpoint($tablet) {
                width: 60%;
            }

            @include breakpoint($mobileOnly) {
                width: 100%;
            }
        }

        .subscriptionCurrentPlanBox {
            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    margin: 0;
                    padding: 5px 0;
                    font-size: 16px;

                    p {
                        margin: 0;
                        color: #b5b5b5;
                        font-size: 14px;
                        font-style: italic;
                    }

                    strong {
                        margin-right: 10px;
                    }
                }
            }

            &.histroy {
                border-bottom: 2px solid #f2f2f2;
                margin: 15px 0;
                padding-bottom: 15px;

                &:last-child {
                    border: 0;
                }
            }

            .subscriptionInActiveUser {
                padding: 36px 0;
                margin: 10px 0;
            }
        }

        .histroyLink {
            margin: 0;
            padding: 0;
            text-align: right;

            a {
                color: $primary-blue;
            }
        }
    }
}