@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.register {
  background-color: $secondary-grey;
  min-height: 100vh;
  margin-top: 80px;

  .registrationForm {
    .RegistrationFormRow {
      display: flex;
      flex-flow: row wrap;

      @include breakpoint($mobileOnly) {
        display: block;
      }

      .RegistrationFormCol {
        width: 50%;

        @include breakpoint($mobileOnly) {
          width: unset;
        }
        &.RegistrationFormBg {
          padding: 50px;
          text-align: center;
          position: fixed;

          @include breakpoint($mobileOnly) {
            position: unset;
            padding: 30px;
          }
          img {
            max-width: 450px;
          }
        }

        &.RegistrationFormContainer {
          padding: 50px;
          margin-left: 50%;

          @include breakpoint($mobileOnly) {
            padding: 10px;
            margin-left: 0;
          }

          h2 {
            font-weight: 900;
            font-size: 52px;
            color: $primary-blue;
            line-height: 62px;
            text-shadow: 0 13.36px 8.896px #c4b59d, 0 -2px 1px #fff;

            @include breakpoint($mobileOnly) {
              font-size: 24px;
              line-height: unset;
            }
          }
         

          a {
            color: $primary-red;
          }

          .ant-form-item-label>label {
            color: $primary-black;
            font-weight: 600;
          }

          .ant-input {
            box-shadow: 0px 3px 6px #00000017;
            border-radius: 10px;
            height: 36px;
          }

          .ant-input-number {
            box-shadow: 0px 3px 6px #00000017;
            border-radius: 10px;
            height: 36px;
          }

          textarea.ant-input {
            box-shadow: 0px 3px 6px #00000017;
            border-radius: 10px;
            height: 80px;
          }

          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            box-shadow: 0px 3px 6px #00000017;
            border-radius: 10px;
            height: 36px;
          }

          .ant-input-affix-wrapper {
            box-shadow: 0px 3px 6px #00000017;
            border-radius: 10px;
            height: 36px;

            .ant-input {
              box-shadow: 0px 0px 0px #00000017;
              border-radius: 0px;
              height: auto;
            }
          }

          .registerBtn {
            box-shadow: 0px 7px 8px #ed841a73;
            border-radius: 13px;
            background-color: $primary-orange;
            border-color: $primary-orange;
            width: 175px;
            height: 50px;
            margin: 20px 0;
            font-size: 20px;
            line-height: 30px;
          }

          .isEmailVerified {
            p {
              width: 63%;
              font-size: 24px;
            }
          }
        }
        .ant-btn[disabled], .ant-btn[disabled]:active, .ant-btn[disabled]:focus, .ant-btn[disabled]:hover {
          color: rgba(0,0,0,.25) !important;
          border-color: #d9d9d9 !important;
          background: #f5f5f5 !important;
          text-shadow: none !important;
          box-shadow: none !important;
      }
      }
    }
  }
  span.policyLink {
    color: #FD5334;
    cursor: pointer;
    font-weight: 500;
  }
}
.custom-option:hover{
  background-color: #A0ADC3 !important;
  color: white;
}