@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.jobPostForm {
  padding: 15px;
  margin: 0;
  background-color: #fff;
  /* border: 10px solid rgba(237, 132, 26, 0.5); */
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;

  .ant-form-item-label>label {
    font-weight: 600;
  }

  .highLightStraps {
    background-color: #A0ADC3;
    color: $white;
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
    margin: 20px 0;
    border-radius: 5px;
  }

  .skillsBox {
    display: inline-flex;
    width: 50%;

    @include breakpoint($mobileOnly) {
      width: 100%;
    }
  }

  button {
    margin: 15px 15px 0px 0px;
  }

  .languageCol {
    position: relative;
    margin-bottom: 10px;

    span {
      position: absolute;
      top: 5px;
      right: 2px;
      cursor: pointer;
    }
  }

  button.languageBtn {
    background: none;
    font-size: 16px;
    font-weight: 600;
    color: $primary-orange;
    padding: 2px 20px;
    margin: 15px 0 0;
    border: 1px solid $primary-orange;
    border-radius: 5px;
    cursor: pointer;
  }
  .question-info-section{
    display: flex;
    width: 100%;
    @include breakpoint($mobileOnly) {
      display: block;
    }
    .question-message-limit{
      width: 60%;
      font-weight: 500;
      margin-top: 8px;
      @include breakpoint($tablet) {
        width: 55%;
      }
      @include breakpoint($mobileOnly) {
        width: 100%;
      }
      .question-note-icon{
        color: #ed9f0b;
      }
    }
    .question-delete-all-btn{
      width: 40%;
      margin-left: 8px;
      text-align: end;
      @include breakpoint($tablet) {
        width: 45%;
        margin-top: 8px;
      }
      @include breakpoint($mobileOnly) {
        width: 100%;
        text-align: left;
        margin-left: 0;
        margin-top: 8px;
      }
      button{
        margin: 0;
      }
    }
  }
}
.generate-ai{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left:  10px;
  .ai-button {
    color: black;
    padding: 4px 28px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
}
.create-question-btn {
  text-align: end;
}

.wrapper-class {
  border: 0px solid #ccc;
}

.editor-class {
  background-color: #fff;
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.question-grid {
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 9%);
  padding: 10px 10px;
  margin-bottom: 10px;

  .question-action-btn {
    display: flex;

    .ant-btn.ant-btn-primary {
      min-width: 46px;
      height: 40px;
      margin: 0px 15px 0px 0px;
      @include breakpoint($mobileOnly) {
        margin: 0;
      }
    }
    .question-edit-btn{
      @include breakpoint($mobileOnly) {
        margin-right: 5px !important;
      }
    }
  }

  .question-correct-answer {
    color: $primary-green;
    font-weight: 600;
  }
.question-cursor-pointer{
  cursor: pointer;
}
  .text-over-flow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.view-question-modal{
  .view-question{
    margin-bottom: 10px;
  }
.view-question-heading{
  font-weight: 600;
}
.question-correct-answer {
  color: $primary-green;
  font-weight: 600;
}
}

.subscription-upgrade-modal{
  text-align: center;
  .subscription-upgrade-modal-text{
    padding: 40px 0;
    a{
      color: #ed9f0b;
    }
  }
  .subscription-upgrade-modal-btn{
    .upgrade-btn{
      margin-right: 10px;
    }
  }
}
.location-input {
  
  /* Add your input field styles here */
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 5px;
  width: 100%;
  transition: border-color 0.3s;
  
  &:hover, &:focus {
    border-color: #40a9ff;
  }
}
.rdw-embedded-wrapper{
  display: none !important;
}
.rdw-image-wrapper{
  display: none !important;
}
.rdw-remove-wrapper{
display: none !important;
}
.rdw-history-wrapper{
  display: none !important;
}
.rdw-link-wrapper{
  display: none !important;
}