@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.jobsCardContainer {
    .jobsRow {
        border: 0px solid rgba(237, 132, 26, 0.5);
        border-radius: 10px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-color: $white;
        padding: 15px;
        margin: 0;

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            display: flex;
            flex-flow: row wrap;

            @include breakpoint($mobileOnly) {
                display: block;
            }

            li {
                margin: 0 10px 0 0;
                padding: 0;
                align-items: center;
                font-size: 12px;
                color: $secondary-black;

                img {
                    width: 26px;
                    margin: 10px 5px 0 0;
                }
            }
        }
       

        .jobsHead {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            border-bottom: 1px solid $primary-grey;
            padding-bottom: 10px;
            margin-bottom: 10px;

            .jobsHeadCol {
                position: relative;
                .download-icon{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                }
                .tag{
                    position: absolute;
                    top:-25px;
                    right:-15px;
                }
                &:nth-child(1) {
                    width: 100%;

                    @include breakpoint($mobileOnly) {
                        width: 100%;
                    }
                }

                .ant-select {
                    color: $primary-orange;
                }

                .ant-select-single.ant-select-open .ant-select-selection-item {
                    color: $primary-orange;
                }

                .ant-select:not(.ant-select-customize-input) .ant-select-selector {
                    border: 1px solid $primary-orange;
                    border-radius: 10px;
                }

                .ant-select-arrow {
                    color: $primary-orange;
                }

                li {
                    margin: 0 25px 0 0;
                    padding: 0;
                    align-items: center;
                    display: flex;
                    font-size: 13px;
                    color: $secondary-black;
                }

                .JobInfo {
                    line-height: 1.4;

                    span.infoNameType {
                        display: block;
                        font-size: 11px;
                        font-style: italic;
                    }

                    span.infoNameValue {
                        font-weight: 600;
                    }
                }

                h3 {
                    color: #2d83bf !important;
                    margin: 0;
                    background-color: $white;

                    small {
                        display: block;
                    }
                }
            }
        }

        .jobsInfo {
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            @include breakpoint($mobileOnly) {
                display: block;
            }

            .jobsInfoColWidth {
                width: 100% !important;
            }

            .jobsInfoCol {
                ul {
                    li {
                        @include breakpoint($mobileOnly) {
                            margin: 2px !important;
                        }
                    }
                }

                .buttons {
                    li {
                        @include breakpoint($mobileOnly) {
                            margin: 2px;
                        }
                    }
                }

                &:nth-child(1) {
                    width: 65%;

                    @include breakpoint($tablet) {
                        width: 50%;
                    }

                    @include breakpoint($mobileOnly) {
                        width: 100%;
                    }
                }

                &:nth-child(2) {
                    width: 35%;
                    text-align: right;
                    display: inline-flex;
                    justify-content: flex-end;

                    @include breakpoint($tablet) {
                        width: 50%;
                    }

                    @include breakpoint($mobileOnly) {
                        margin-top: 10px;
                        width: 100%;
                        justify-content: start;
                    }

                    img {
                        width: 30px;
                    }

                    a {
                        span {
                            text-decoration: none !important;
                        }
                    }

                    span {
                        border: 1px solid $primary-orange;
                        border-radius: 5px;
                        padding: 5px 15px;
                        min-width: 80px;
                        text-align: center;
                        color: $secondary-black;
                        cursor: pointer;
                        font-size: 13px;
                        font-weight: 600;
                        box-shadow: 0px 3px 10px #0000000f;

                        @include breakpoint($mobileOnly) {
                            padding: 5px 12px;
                        }

                        &:hover {
                            color: $white;
                            background-color: $primary-orange;
                        }
                    }

                    .buttons {
                        @include breakpoint($mobileOnly) {
                            margin: 0px;
                        }
                    }

                    ul {
                        li {
                            &:after {
                                content: "";
                                color: $primary-grey;
                                position: relative;
                                left: 0px;

                                @include breakpoint($mobileOnly) {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                ul {
                    @include breakpoint($mobileOnly) {
                        display: flex;
                        flex-flow: row wrap;
                    }

                    li {
                        @include breakpoint($mobileOnly) {
                            margin: 0;
                        }

                        &:after {
                            content: "|";
                            color: $primary-grey;
                            position: relative;
                            left: 2px;

                            @include breakpoint($mobileOnly) {
                                display: none;
                            }
                        }

                        &:last-child {
                            &:after {
                                content: "";
                            }
                        }

                        span {
                            padding: 0 5px 0 0;
                            color: $secondary-black;
                            font-weight: 500;
                            font-size: 11px;
                        }
                    }
                }

                a span:first-child {
                    text-decoration: underline;
                    color: $secondary-black;
                }
            }
        }
    }
}