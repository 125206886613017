@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.forgetPassword {
  background-color: $secondary-grey;
  .forgetPasswordHeader {
    height: 5rem;
    background-color: $white;
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .forgetPasswordRow {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    min-height: calc(100vh - 0px);
    @include breakpoint($mobileOnly) {
      display: block;
    }
    .forgetPasswordColImg{
      width: 50%;
      padding: 130px 50px 0;
      @include breakpoint($mobileOnly) {
        width: 100%;
      }
      &.imageLady {
        text-align: center;
      }
      img {
        max-width: 450px;
      }
    }
    .forgetPasswordCol {
      width: 50%;
      padding: 130px 50px 0;
      @include breakpoint($tablet) {
        padding: 80px 30px 0 !important;
      }
      @include breakpoint($mobileOnly) {
        width: 100%;
        padding: 30px 30px 0;
      }
      .ant-form-item-control-input-content{
        @include breakpoint($mobileOnly) {
          text-align: center;
        }
      }
      h2 {
        font-weight: 900;
        font-size: 52px;
        color: $primary-blue;
        line-height: 62px;
        text-shadow: 0 13.36px 8.896px #c4b59d,0 -2px 1px #fff;
        width: 40%;
        @include breakpoint($mobileOnly) {
          font-size: 24px;
          line-height: unset;
          width: unset;
        }
      }
      p {
        font-size: 20px;
      }
      .resetPopup{
        p{
          width: 50%;
          @include breakpoint($mobileOnly) {
            width: 100%;
          }
        }
        
      }
      .forgetPwdRow {
        input {
          border: 0;
          border-bottom: 5px solid $third-grey;
          max-width: 500px;
          width: 100%;
          height: 60px;
          font-size: 20px;
          line-height: 30px;
          background: transparent;
          padding: 0;
          &:hover {
            background-color: $secondary-grey;
            border-color: $third-grey;
          }
          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $third-grey;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $third-grey;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $third-grey;
          }
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px $secondary-grey inset !important;
        }
        .ant-input:focus,
        .ant-input-focused {
          box-shadow: none;
        }
        .ant-form-item-explain-error {
          color: $primary-red;
        }
      }
      .forgetPasswordBtn {
        box-shadow: 0px 7px 8px #ed841a73;
        border-radius: 13px;
        background-color: $primary-orange;
        border-color: $primary-orange;
        // width: 175px;
        height: 60px;
        margin: 50px 0 0 0 ;
        font-size: 20px;
        line-height: 30px;
        @include breakpoint($mobileOnly) {
          margin: 20px 0 0 0 ;
        }
      }
      .backToLogin{
        p{
          font-size: 16px;
          font-weight: 500;
        }
        .backToLoginLink{
          color: $primary-red;
        }
        @include breakpoint($mobileOnly) {
         text-align: center;
        }
      }
    }
  }
}
