@import "../../assets/styles/colours";
@import "../../assets/styles/responsive"; 
.home {
  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: 1px solid $third-grey;
  }
  .ant-tabs-tab {
    font-weight: 700;
    font-size: 16px;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $primary-orange;
  }
  .ant-tabs-ink-bar {
    background: $primary-orange;
  }
  .ant-tabs-tab:hover {
    color: $primary-orange;
  }
  .ant-tabs-tab-btn:focus,
  .ant-tabs-tab-remove:focus,
  .ant-tabs-tab-btn:active,
  .ant-tabs-tab-remove:active {
    color: $primary-orange;
  }
  .jobsCardContainer {
    .jobsRow {
      margin: 0 0 15px 0;
    }
  }
}

#profilebtn {
  border-radius: 40%;
    position: absolute;
    right: 5px;
    border: 1px solid transparent;
    top: 0;
    width: 70px;
    height: 50px;
}
#chatbtn{
  border-radius: 40%;
  position: absolute;
  right: -25px;
  border: 1px solid transparent;
  bottom: -5%;
  z-index: 2000000;
  width: 63px;
  height: 70px;
}
#home{
  left: 4%;
    width: 40px;
    content: '';
    height: 38px;
    top: 10.5%;
    position: absolute;
    width: 90px;
}
#jobseeker{
  left: 36%;
    width: 40px;
    content: "";
    height: 40px;
    top: 6%;
    position: absolute;
    width: 110px;
}
// .introjs-tooltip {
//   // top: 137px!important;
 
// }
#published{
  position: absolute;
    top: 5px;
    left: 0;
    height: 40px;
    width: 140px;
}
.home{
  position: relative;
}
#archive{
  position: absolute;
    top: 5px;
    left: 20;
    height: 40px;
    width: 140px;
}
#onhold{
  position: absolute;
    top: 5px;
    left: 10;
    height: 40px;
    width: 140px;
}
#dpstep{
  z-index: 999999999999;
}
// .introjs-dontShowAgain{
//   margin-top: 5px !important;
//   display: flex;
//   align-items: center;
// }
// .introjs-dontShowAgain input[type=checkbox] {
//   height: 15px;
//   width: 15px;
// }
// .introjs-dontShowAgain label{
//   // margin-top: 5px !important;
//   color: #fff !important;
//   font-weight: 600 !important;
//   background-color: transparent !important;
// }