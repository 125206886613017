@import "../../../assets/styles/colours";
@import "../../../assets/styles/responsive";
.applicant {
  .renderJobOfferedList {
    h2{
      text-align: center;
      padding: 20px;
    }
    .offeredList {
      .applicantViewInfo {
      width: 100%;
      position: relative;
      z-index: 1;
      &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
        @include breakpoint($mobileOnly) {
          border: none;
        }
      }
        .applicantRow {
          display: flex;
          align-items: center;
          @include breakpoint($mobileOnly) {
            display: block;
          }
          .applicantCol {
            width: 50%;
            &:nth-child(1) {
              width: 35%;
              @include breakpoint($mobileOnly) {
                width: 100%;
              }
            }
            &:nth-child(2) {
              width: 20%;
              @include breakpoint($mobileOnly) {
                width: 100%;
              }
            }
            &:nth-child(3) {
              width: 45%;
              @include breakpoint($mobileOnly) {
                width: 100%;
              }
            }
            .applicantInfoRow {
              display: flex;
              .applicantInfoCol {
                width: 50%;
                padding: 7px 0;
                &:first-child {
                  font-weight: 500;
                  width: 25%;
                }
                &:last-child {
                  width: 75%;
                }
              }
            }
            .viewResumeBox {
              display: flex;
              .applicantStatusBtn{
                background-color: transparent;
                border: 1px solid #ed9f0b;
                color: #393939;
                border-radius: 5px;
                padding: 8px 10px;
                cursor: pointer;
                min-width: 106px;
                display: inline-block;
                text-align: center;
                font-weight: 500;
                font-size: 12px;
                margin: 3px 5px 3px 0px;
                height: 35px;
                &:hover {
                  background-color: $primary-orange;
                  color: $white;
                }
              }
              @include breakpoint($mobileOnly) {
                display: block;
              }
            }
          }
        }
      }
      // .applicantRating {
      //   width: 20%;
      // }
    }
  }
}
