@import "../../../assets/styles/colours";
.headerBottomRow {
  background-color: $primary-blue;
  padding: 10px 50px;
  height: 50px;
  display: flex;
  align-items: center;

  .ant-menu-horizontal {
    border-bottom: 0px;
  }
  .ant-menu {
    background: $primary-blue;
    width: 100%;
  }
  .ant-menu-horizontal > .ant-menu-item a {
    color: $white;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-menu-horizontal > .ant-menu-item-selected a {
    color: $white;
    font-size: 16px;
    line-height: 24px;
  }
  .ant-menu-item-selected a,
  .ant-menu-item-selected a:hover {
    color: $white;
  }
  .ant-menu-horizontal > .ant-menu-item a:hover {
    color: $white;
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 3px solid $primary-orange;
  }
  .ant-menu-horizontal > .ant-menu-item::after,
  .ant-menu-horizontal > .ant-menu-submenu::after {
    bottom: -12.5px;
  }
}
