@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $point == mobile-only {
    @media (max-width: 767px) {
      @content;
    }
  }
}

$desktop: desktop;
$tablet: tablet;
$mobileOnly: mobile-only;
