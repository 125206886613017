@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.studentListTable {
  .ant-table table {
    border-radius: 0px 0px 0 0;
    border-spacing: 0px 7px;

    tr {
      td {
        &:nth-child(1) {
          width: 30%;

          @include breakpoint($tablet) {
            width: 25%;
          }
        }

        &:nth-child(2) {
          width: 30%;

          @include breakpoint($tablet) {
            width: 30%;
          }
        }

        &:nth-child(3) {
          width: 20%;

          @include breakpoint($tablet) {
            width: 25%;
          }
        }

        &:nth-child(4) {
          width: 20%;

          @include breakpoint($tablet) {
            width: 20%;
          }
        }
      }
    }
  }

  .ant-btn {
    &.statusBtn {
      font-size: 12px;
      font-weight: 600;
      border-color: $primary-orange;
      margin: 0 2px 5px;
      border-radius: 5px;

      &:hover {
        background-color: $primary-orange;
        color: $white;
      }
    }
  }

  table {
    tr {
      th {
        color: $primary-blue;
      }

    }
  }

  .textOverFlow {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.paginationRow {
  margin: 30px 0;
  float: right;
}

.terms-and-service {
  margin-top: 16px;

  .terms-offer-btn {
    text-align: end;

    .terms-service-btn {
      margin-right: 10px;
    }
  }
}

.view-assessment {
  .view-assessment-result{
    display: flex;
    width: 100%;
  .assessment-result{
    text-align: center;
    padding: 22px 0;
    padding: 10px 10px;
    margin-bottom: 10px;
    background-color: #ffffff;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 9%);
    width: 50%;
    height: 110px;
    &:nth-child(1) {
      margin-right: 15px;
    }
    .assessment-grid{
      padding: 20px 0;
      .assessment-title{
        font-weight: 500;
      }
    }
    .result-color{
      color: #187300;
    }
  }
}
  .assessment-question-answer {
    margin-bottom: 16px;
    .question-with-icon{
      display: flex;
      .question-correct-answer{
        color: #187300;
      }
      .question-wrong-answer{
        color: red;
      }
    }
    .question-options {
      margin: 0 0 2px 0;
      
      position: relative;
      padding: 6px 5px;
    }
    .assessment-question{
      font-weight: 500;
      margin-left: 4px;
    }
    .assessment-question p{
      margin-bottom: 2px;
    }
    .correct-answer{
      background-color: #d4edda;
    }
    .wrong-answer{
      background-color: #f8d7da;
    }
    .options-bg-color{
      background-color: #f1f1f1;
    }
  }
}