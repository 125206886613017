@import "colours";
@import "responsive";

body {
  font-family: "Poppins", sans-serif !important;
  color: $primary-black;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #d4d4d4;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(136, 135, 135);
}

::-webkit-scrollbar:horizontal {
  height: 8px;
}

::-webkit-scrollbar-track:horizontal {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb:horizontal {
  background: #d4d4d4;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover:horizontal {
  background: rgb(136, 135, 135);
}

.overflow-scroll::-webkit-scrollbar {
  width: 7px;
}

.overflow-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgb(255, 255, 255);
}

.overflow-scroll::-webkit-scrollbar-thumb {
  background-color: $primary-blue;
  outline: 1px solid #f3f2f1;
}

section.ant-layout.layoutStyle {
  min-height: calc(100vh - 100px);
  background-color: $secondary-grey;
}

section.ant-layout.placementofficerLayoutStyle {
  min-height: calc(100vh - 50px);
  background-color: $secondary-grey;
}

.ant-layout-content {
  max-width: calc(100% - 100px);
  margin: 0 auto;
  width: 100%;
  padding: 15px;

  @include breakpoint($mobileOnly) {
    max-width: calc(100% - 0px);
  }
}

.header.ant-layout-header {
  height: auto;
  padding: 0;
  color: $primary-black;
  background: $white;
}

.ant-btn.ant-btn-primary {
  color: $white;
  background-color: $primary-orange;
  border-color: $primary-orange;
  box-shadow: 0px 3px 6px #ed841a78;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 15px !important;
  height: auto;
  min-width: 100px;
  text-align: center;
  @include breakpoint($mobileOnly) {
    min-width: 115px;
  }

  &:hover {
    background-color: $primary-orange;
    border-color: $primary-orange;
  }

  &.backBtn {
    margin-right: 10px;
  }
}

.ant-btn.ant-btn-secondary {
  color: $primary-orange;
  border-color: $primary-orange;
  box-shadow: 0px 3px 6px #ed841a78;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 400;
  padding: 5px 15px !important;
  height: auto;
  min-width: 100px;
  text-align: center;
  @include breakpoint($mobileOnly) {
    min-width: 115px;
  }

  &:hover {
    background-color: $white;
    border-color: $primary-orange;
    color: $primary-orange;
  }
}

.cancel-btn {
  color: $black !important;
  background-color: $primary-grey !important;
  border-color: $primary-grey !important;
  border-radius: 8px !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  padding: 5px 20px !important;
  height: auto !important;
  min-width: 125px !important;
  text-align: center !important;

  &:hover {
    background-color: $primary-grey;
    border-color: $primary-grey;
  }

  &.backBtn {
    margin-right: 10px;
  }
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  border-color: $primary-orange !important;
  background: $primary-orange !important;
}

.headingContainer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 10px 0 20px;

  .headingTitle {
    width: 50%;
    @include breakpoint($tablet) {
      width: 60%;
    }
    @include breakpoint($mobileOnly) {
      width: 100%;
      order: 2;
      margin-top: 30px;
    }

    h3 {
      color: $primary-blue;
      font-size: 32px;
      margin: 0;
      font-weight: 700;
      // text-shadow: 0 13.36px 8.896px #c4b59d, 0 -2px 1px #fff;
      @include breakpoint($mobileOnly) {
        font-size: 24px;
      }
    }
  }

  .headingButton {
    width: 50%;
    text-align: right;
    @include breakpoint($tablet) {
      width: 40%;
    }
    @include breakpoint($mobileOnly) {
      width: 100%;
      text-align: center;
    }
  }
}

.ant-popover {
  .ant-popover-buttons button {
    // color: #ffffff;
    // background-color: #ed841a;
    // border-color: #ed841a;
    box-shadow: none;
    border-radius: 8px;
    padding: 5px;
    height: auto;
    min-width: 60px;
  }

  .ant-popover-buttons .ant-btn-primary {
    color: #ffffff;
    background-color: #ed9f0b;
    border-color: #ed9f0b;
    box-shadow: none;
    border-radius: 8px;
    font-size: 14px;
    padding: 5px !important;
    height: auto;
    min-width: 90px;
  }
}

.errorMsg {
  text-align: center;
  margin: 50px 0;
}

input[type="submit"i]:focus-visible {
  outline: 1px dashed #ccc;
}
.HomePagination {
  display: none;
}
.home {
  .HomePagination {
    display: block;
    float: right;
  }
}
.container {
  .ant-pagination-item-active a {
    color: $primary-orange !important;
  }
  .ant-pagination-item-active {
    border-color: $primary-orange;
    color: $primary-orange;
  }
  .ant-pagination-item a {
    color: #2c4394;
  }
  .ant-pagination-item:focus-visible,
  .ant-pagination-item:hover {
    border-color: $primary-orange;
    color: $primary-orange;
  }
  .ant-pagination-item:focus-visible a,
  .ant-pagination-item:hover a {
    color: $primary-orange;
  }
}
.expandableContent {
  .expandableRow {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding: 7px 0;
    .expandableColHead {
      width: 20%;
      font-weight: 600;
    }
    .expandableColValue {
      width: 80%;
    }
  }
}
// disabled button
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
  text-shadow: none !important;
  box-shadow: none !important;
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
}
