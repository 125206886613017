@import "../../assets/styles/colours";
@import "../../assets/styles/responsive";

.internetError {
  line-height: initial;
  .errorMsg{
    h1{
        font-size: 18px;
    }
  }
  .headerTopRow {
    padding: 10px 15px 5px;
    background-color: $white;
    height: 50px;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px rgb(0 0 0 / 9%);
    @include breakpoint($mobileOnly) {
        justify-content: center;
      }
  }
}
