.employer-subscription-cards {
    margin-bottom: 40px;

    .subscription-card-col {
        margin-bottom: 14px;

        .subscription-card {
            border: 1px solid #e2e2e2;
            padding: 8%;
            font-size: 14px;
            font-weight: 400;
            margin-right: 14px;
            background-color: #fff;
            box-shadow: 0 3px 10px rgb(0 0 0 / 6%);
            border-radius: 7px;
            min-height: 100%;
            display: flex;
            flex-direction: column;

            .subscription-card-header {
                text-align: center;

                .subscription-plan-title {
                    font-size: 1.6rem;
                    font-weight: 600;

                    .subscription-plan {
                        color: #2C4394;
                    }
                }

                .subscription-plan-title p::after {
                    position: absolute;
                    left: calc(50% - 24px);
                    display: block;
                    width: 40px;
                    border-bottom: 2px solid orange;
                    content: "";
                }

                .subscription-plan-cost {
                    .plan-cost {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 6px;
                        font-family: Sailec-Regular, Helvetica, sans-serif;

                        .original-price {
                            font-weight: 700;
                            font-size: 1.4rem;
                            color: #4d4d4d;
                            text-decoration: line-through;
                        }

                        .offer-price {
                            font-size: 2.4rem;
                            color: #4d4d4d;
                            font-weight: 900;
                        }
                    }

                }
            }

            .subscription-divider {
                display: block;
                border-bottom: 2px solid #ed9f0b;
            }

            .subscription-plan-details {
                padding: 20px 0 30px 0;

                .subscription-plans {
                    margin-bottom: 6px;
                }

                .icon-color {
                    color: green;
                    margin-right: 10px;
                }
            }

            .subscriptionPlanButton {
                text-align: center;
                margin-top: auto;
            }
        }
    }

    

    


}
.promo-container {
    // border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1px 10px;
    margin: 5px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    h3 {
        margin-bottom: 5px;
    }

    p {
        margin-bottom: 10px;
    }

    .apply-btn {
        // background-color: #fff ;
        font-weight: bolder;
        color: #ed9f0b;
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        // transition: background-color 0.3s ease;

        &:hover {
            background-color: #ed9f0b;
            color: #fff;
            border-radius: 10px;
        }
    }

}
.discountedprice{
    font-size: 24px;
    font-weight: 700;
}
.subscription-divider {
    display: block;
    border-bottom: 2px solid #ed9f0b;
}
.subscriptiondetails{
    margin-top: 10px;
}
.headersub{
    font-size: 20px;
    color: #2C4394;
    font-weight: 600;
}
.footerAmount{
    font-size: 18px;
    color: #2C4394;
    font-weight: 600;
}
.subscriptiondetailItem{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.space-between{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}
.gradedAmount{
    // color: #ccc;
    font-size: 13px;
    text-decoration: line-through;
    margin-right: 7px;
}
.showAmount{
    // font-size: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.couponsContainer{
    max-height: 175px;
    overflow-y: scroll;
}
.couponsContainer::-webkit-scrollbar{
    display: none;
}

.apply-coupon {
    cursor: pointer;
    color: 'black';
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
    // border-bottom: 2.5px dotted #ed9f0b;
    border: 1px solid #fff;
    border-radius: 10px;
    padding: 8px 5px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.apply-coupon::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    // border-top: 6px solid #1890ff;
    bottom: -6px;
    right: 12px;
}

.apply-coupon:hover {
    // text-decoration: underline;
    cursor: pointer;
}
.arrow-style{
    font-size: 13px;
    font-weight: 600;
    color:#ed9f0b!important
}